import React from 'react';
import styled from 'styled-components';
import { laptopSize, mobileSize } from '../../utils';
import { theme } from '../../utils/theme';
import ClientBox from '../ClientBox';
import { NewAppTitle } from '../ui';

import realtorLogo from '../../images/work/logos/Realtor.png';
import realtorImg from '../../images/work/client-image/Realtor.png';
import imprintLogo from '../../images/work/logos/Imprint.png';
import imprintImg from '../../images/work/client-image/Imprint.png';
import imprintBg from '../../images/work/backgrounds/Imprint.png';
import tappingLogo from '../../images/work/logos/TS.png';
import tappingImg from '../../images/work/client-image/Tapping Solution.png';

export default function OurWorkPreview() {
  return (
    <OurWorkContainer>
      <JourneyTitle>See Our Work</JourneyTitle>
      <OurWorkDiv>
        {/* <OurWorkImg src={realtorPortalCard} alt="Realtor Portal Card" />
          <OurWorkImg src={imprintPortalCard} alt="Imprint Portal Card" /> */}
        {/* <OurWorkImg src={tappingPortalCard} alt="Tapping Solution Portal Card" /> */}
        <ClientBox
          summary="Home Buying Made Easy"
          clientImg={realtorImg}
          clientLogo={realtorLogo}
          // clientBg={realtorBg}
          bgColor="#D6262D"
          name="realtor"
          // cardHeight={457}
          cardHeight={457}
          caseStudy="realtor"
        />
        <ClientBox
          summary="Learn Visually. Read Faster. Remember More."
          clientImg={imprintImg}
          clientLogo={imprintLogo}
          clientBg={imprintBg}
          bgColor="linear-gradient(172.42deg, #79C9CD 6.32%, #75C7CC 52.82%, #6AC3C8 95.75%)"
          name="imprint"
          cardHeight={457}
          caseStudy="imprint"
        />
        <ClientBox
          summary="Relax, Stress Less, Sleep Well"
          clientImg={tappingImg}
          clientLogo={tappingLogo}
          bgColor="#DFE1F5"
          name="tapping"
          cardHeight={457}
          textColor="#053148"
          caseStudy="tapping-solution"
          noMask
        />
      </OurWorkDiv>
    </OurWorkContainer>
  );
}

const OurWorkContainer = styled.div`
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 60px;
  background-color: ${theme.WHITE_COLOR};
  @media ${mobileSize} {
    padding-bottom: 0;
  }
`;

const JourneyTitle = styled(NewAppTitle)`
  margin-bottom: 48px;
  color: ${theme.MAIN_DARK_BLUE};
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  @media ${laptopSize} {
    font-weight: 800;
    text-align: center;
  }
`;

const OurWorkDiv = styled.div`
  width: 100%;
  max-width: 1276px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 457px;
  grid-gap: 17px;
  padding-left: 30px;
  padding-right: 30px;
  @media ${laptopSize} {
    /* grid-template-columns: 1fr; */
    /* grid-template-rows: 500px; */
    display: flex;
    flex-direction: column;
  }
  @media ${mobileSize} {
    /* grid-template-columns: 1fr; */
    padding: 0;
    grid-gap: 0;
  }
`;
