import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../../static/theme';
import PageData from '../components/ridge/content/app-design.yaml';
import { HeroText, H2, H3, H4, Para, Quote } from '../components/ridge/global/Text';
import Layout from '../components/ridge/Layout';
import Hero from '../components/ridge/sections/Hero';
import Graphic from '../components/ridge/sections/Graphic';
import TwoColumnIconCards from '../components/ridge/sections/TwoColumnIconCards';
import Resources from '../components/ridge/sections/Resources';
import { NavBar } from '../components/segments/NavBar';
import OurWorkPreview from '../components/segments/OurWorkPreview';
import LetsConnectSection from '../components/segments/LetsConnectSection';
import { IndexLayout } from '../layouts';
import { PageContainer } from '../components/ridge/global/Wrappers';
import DarkFooter from '../components/segments/DarkFooter';

const PlaceHolder = styled.section`
  padding: 250px 0;
  background-color: lightgrey;
  text-align: center;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
`;

// markup
const appDesign = () => {
  return (
    <IndexLayout>
      <NavBar invert alwaysOpaque />
      <PageContainer>
        <Hero
          heading={PageData.hero.heading}
          body={PageData.hero.body}
          formHeading={PageData.hero.form.heading}
        />

        <Graphic image={PageData.graphic.image} />

        <TwoColumnIconCards
          heading={PageData.process.heading}
          body={PageData.process.body}
          items={PageData.process.iconCards}
        />

        <OurWorkPreview />
        <LetsConnectSection />

        <Resources
          heading={PageData.resources.heading}
          body={PageData.resources.body}
          link={PageData.resources.link}
          items={PageData.resources.items}
        />
        <DarkFooter/>
      </PageContainer>
    </IndexLayout>
  );
};

export default appDesign;
